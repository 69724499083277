import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import maarif1 from "../../assets/img/maarif1.png"
import maarif2 from "../../assets/img/maarif2.png"

export default function MaarifProjesi() {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="T.C Maarif Vakfı Projesi" />
      <div className="projeler-container page">
        <div className="ic-proje">
          <img src={maarif1} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("maarif-vakfi")}
            <br />
            <span>{t("project-sector")}</span>: {t("egitim")}
            <br />
            <span>{t("project-subject")}</span>: {t("logonun-yorumu")}
            <br />
            <span>{t("project-artwork")}</span>: {t("vakif-duasi")}
          </div>
          <img src={maarif2} alt="" className="proje-icGorsel" />
        </div>
      </div>
    </Layout>
  )
}
